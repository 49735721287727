import React from "react";
import parse from 'html-react-parser';
import arrow from "../../images/accordion_arrow.svg"


export default function Accordion({accordion_state, index, clickFn, icon, header, text}){

    let [rotate, setRotate] = React.useState(1);

    function state(){
        return accordion_state === true ? -1 : index
    }

    React.useEffect(() => {
        if(accordion_state == false){
            if(rotate % 2 == 0) setRotate(rotate => rotate + 1)
        } else {
            if(rotate % 2 == 1) setRotate(rotate => rotate + 1)
        }
    }, [accordion_state])

    return(
        <div className={`faq_accordion ${accordion_state ? " active" : ""}`} onClick={() => clickFn(state)}>
            <div className="accordion_icon">
                <img src={icon} alt="" />
            </div>
            <div className="accordion_content">
                <div className="accordion_top">
                    <p className="accordion_header">{header}</p>
                    <div><img src={arrow} style={{rotate: `${rotate * 180}deg`}} alt="" /></div>
                </div>
                <p className={`accordion_text ${accordion_state ? "active" : ""}`} onClick={(e) => e.stopPropagation()}>
                    <div>{parse(text)}</div>
                </p>
            </div>
        </div>
    )
}