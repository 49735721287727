import React from "react"
import { motion } from "framer-motion"
// import logo from "../images/radiance_logo.svg";

export default function SlideInAnimation() {
    const [startAnimation, setStartAnimation] = React.useState(false);

    React.useEffect(() => {
        let line1 = document.querySelector(".line4")
        console.log(line1.className.baseVal, line1.getTotalLength());
    }, [])

    return(
        <>
            <motion.div 
                    className="slideIn"
                    initial={{top: "110vh"}}
                    animate={{top: "110vh"}}
                    exit={{top: "0%"}}
                    onAnimationStart={() => {
                        setStartAnimation(true)
                        setTimeout(() => {
                            setStartAnimation(false)
                        }, 1200)
                    }}
                    transition={{duration: 0.5, ease: [0.27,0.94,0.48,1.0]}}
                >
                    <svg className={`logo_svg_animate ${startAnimation ? " active" : ""}`} width="355" height="305" viewBox="0 0 355 305" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <clipPath id="clip-mask">
                                <rect x="0" y="0" width="100%" height="100%" fill="white" />
                            </clipPath>
                        </defs>
                        
                        <path className="line1" d="M28 295V6" stroke="#fff" stroke-width="56"/>
                        <path className="line2" d="M56 34H151.5C175 34 218.716 57 215 108.5C211.5 157 171 171.5 149.5 175" stroke="#fff" stroke-width="56"/>
                        <path className="line3" d="M118 149C120.5 191.5 138 258.107 210 273.5C266.5 285.579 321.5 253.5 334.5 240" stroke="#fff" stroke-width="56"/>
                        <path className="line4" d="M272.699 17.7347L273.082 17.8378L273.47 17.9189C274.04 18.038 274.862 18.2053 275.514 18.3372C275.823 18.3998 276.103 18.4563 276.314 18.4988C276.469 18.5395 276.669 18.5921 276.906 18.6547C277.511 18.815 278.349 19.0386 279.275 19.2915C281.223 19.8238 283.234 20.3966 284.393 20.7779L284.412 20.7841L284.43 20.7902C285.346 21.0885 286.246 21.368 287.055 21.6184C293.136 23.5473 299.186 26.2515 305.292 29.4646L305.306 29.4722L305.321 29.4798L306.493 30.0934C314.566 34.4128 321.978 39.5858 329.264 45.5548L317.997 56.5609C316.229 58.2137 314.47 59.9903 312.943 61.532C312.686 61.7924 312.435 62.046 312.191 62.2912C311.467 61.6971 310.534 60.9476 309.668 60.3052L301.328 71.5498L309.668 60.3052C308.009 59.0749 306.545 58.1788 305.485 57.5394C305.371 57.4706 305.264 57.4061 305.164 57.3454C304.247 56.7928 303.857 56.5576 303.43 56.2581L303.308 56.1725L303.184 56.0896C299.852 53.8577 296.533 52.309 293.891 51.1229C293.886 51.1205 293.88 51.1182 293.875 51.1158L292.494 50.4918L292.465 50.4784L292.435 50.4652C285.246 47.2565 277.845 44.6033 269.9 42.7119C269.415 42.5956 268.952 42.4777 268.508 42.3568L268.403 42.328L268.297 42.3009C264.8 41.4064 261.543 41.1356 259.166 40.9653C258.2 40.8877 257.23 40.8082 256.254 40.7278L256.205 40.7237L256.157 40.72C256.053 40.7122 255.949 40.7046 255.846 40.6971C252.418 34.7557 248.647 29.571 244.831 24.6605L244.273 23.9387L244.265 23.9283C241.579 20.4547 238.726 17.2644 235.357 14.1953C248.232 13.5651 260.97 14.5805 272.699 17.7347Z" stroke="#fff" stroke-width="28"/>
                    </svg>
                </motion.div>
                <motion.div 
                    className="slideOut"
                    initial={{top: "0%"}}
                    animate={{top: "-100%"}}
                    exit={{top: "-100%"}}
                    transition={{duration: 0.5, delay: 1.2, ease: [0.27,0.94,0.48,1.0]}}
                >
                    {/* <motion.img 
                        initial={{opacity: 1}}
                        animate={{opacity: 0}}
                        exit={{opacity: 0}}
                        transition={{duration: 0.5, ease: [0.27,0.94,0.48,1.0]}}
                        src={logo} alt="" 
                    /> */}
                    <svg className="logo_svg_animate" width="355" height="305" viewBox="0 0 355 305" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <clipPath id="clip-mask2">
                                <rect x="0" y="0" width="100%" height="100%" fill="white" />
                            </clipPath>
                        </defs>
                        
                        <path className="line1" d="M28 295V6" stroke="#fff" stroke-width="56"/>
                        <path className="line2" d="M56 34H151.5C175 34 218.716 57 215 108.5C211.5 157 171 171.5 149.5 175" stroke="#fff" stroke-width="56"/>
                        <path className="line3" d="M118 149C120.5 191.5 138 258.107 210 273.5C266.5 285.579 321.5 253.5 334.5 240" stroke="#fff" stroke-width="56"/>
                        <path className="line4" d="M272.699 17.7347L273.082 17.8378L273.47 17.9189C274.04 18.038 274.862 18.2053 275.514 18.3372C275.823 18.3998 276.103 18.4563 276.314 18.4988C276.469 18.5395 276.669 18.5921 276.906 18.6547C277.511 18.815 278.349 19.0386 279.275 19.2915C281.223 19.8238 283.234 20.3966 284.393 20.7779L284.412 20.7841L284.43 20.7902C285.346 21.0885 286.246 21.368 287.055 21.6184C293.136 23.5473 299.186 26.2515 305.292 29.4646L305.306 29.4722L305.321 29.4798L306.493 30.0934C314.566 34.4128 321.978 39.5858 329.264 45.5548L317.997 56.5609C316.229 58.2137 314.47 59.9903 312.943 61.532C312.686 61.7924 312.435 62.046 312.191 62.2912C311.467 61.6971 310.534 60.9476 309.668 60.3052L301.328 71.5498L309.668 60.3052C308.009 59.0749 306.545 58.1788 305.485 57.5394C305.371 57.4706 305.264 57.4061 305.164 57.3454C304.247 56.7928 303.857 56.5576 303.43 56.2581L303.308 56.1725L303.184 56.0896C299.852 53.8577 296.533 52.309 293.891 51.1229C293.886 51.1205 293.88 51.1182 293.875 51.1158L292.494 50.4918L292.465 50.4784L292.435 50.4652C285.246 47.2565 277.845 44.6033 269.9 42.7119C269.415 42.5956 268.952 42.4777 268.508 42.3568L268.403 42.328L268.297 42.3009C264.8 41.4064 261.543 41.1356 259.166 40.9653C258.2 40.8877 257.23 40.8082 256.254 40.7278L256.205 40.7237L256.157 40.72C256.053 40.7122 255.949 40.7046 255.846 40.6971C252.418 34.7557 248.647 29.571 244.831 24.6605L244.273 23.9387L244.265 23.9283C241.579 20.4547 238.726 17.2644 235.357 14.1953C248.232 13.5651 260.97 14.5805 272.699 17.7347Z" stroke="#fff" stroke-width="28"/>
                    </svg>
            </motion.div>
        </>
    )
}